#Contact {
    width: 100vw;
    height: 100vh;
    background-color: #243447;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;

    h1 {
        font-family: 'Roboto Mono', monospace;
        font-weight: 700;
        margin-top: 10px;
    color: black;
    font-size: 48px;
    text-align: center;
    color: #E5ECED;
    }
}

.contactInfo {
    position: relative;
    top: 20px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
    align-content: stretch;
    width: 90%;
    height: 90%;
    padding-left: 25px;
    box-sizing: border-box;

    .contactLink {
        text-decoration: none;
        color: #E5ECED;
        display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
    align-content: stretch;
    font-family: 'Roboto Mono', monospace;
    font-size: 1.8rem;
    }

    .contactLink:nth-child(n+0) {
        margin-bottom: -50px
    }
}

.svgItem-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
}

