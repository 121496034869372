@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "anurati";
  src: url(/static/media/anurati-regular-webfont.0b2ba2df.woff) format("woff"), url(/static/media/anurati-regular-webfont.3cff8742.woff2) format("woff2");
  font-weight: normal;
  font-style: normal; }

#Projects {
  width: 100vw;
  height: 100vh;
  background-color: #243447;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch; }

.project {
  color: #E5ECED;
  font-family: "Roboto slab", sans-serif;
  margin: 10px;
  padding: 35px;
  border-width: 15px;
  height: 800px;
  width: 650px; }
  .project h1 {
    font-family: "anurati";
    text-align: center;
    font-size: 26px;
    margin-bottom: 10px; }
  .project .projectImages {
    width: 100%;
    height: 100%; }
  .project p {
    margin-top: 0px;
    font-size: 18px; }
  .project p:nth-child(3) {
    margin-top: 5px; }
  .project a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    text-decoration: none;
    color: #E5ECED;
    font-size: 18px;
    position: relative;
    bottom: 30px; }
    .project a img {
      margin-right: 10px; }
  .project #github {
    position: relative;
    bottom: 50px; }
  .project #reforge {
    position: relative;
    right: 1px; }

#Contact {
  width: 100vw;
  height: 100vh;
  background-color: #243447;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch; }
  #Contact h1 {
    font-family: 'Roboto Mono', monospace;
    font-weight: 700;
    margin-top: 10px;
    color: black;
    font-size: 48px;
    text-align: center;
    color: #E5ECED; }

.contactInfo {
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  width: 90%;
  height: 90%;
  padding-left: 25px;
  box-sizing: border-box; }
  .contactInfo .contactLink {
    text-decoration: none;
    color: #E5ECED;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    font-family: 'Roboto Mono', monospace;
    font-size: 1.8rem; }
  .contactInfo .contactLink:nth-child(n+0) {
    margin-bottom: -50px; }

.svgItem-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible; }

#Skills {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y: 65%; }
  #Skills h1 {
    font-family: 'Roboto Mono', monospace;
    color: #253448;
    font-weight: 700;
    font-size: 48px; }

.skillIcons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: stretch; }

.svgItem {
  height: 250px;
  width: 250px; }

#About {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  background-color: transparent; }
  #About .image {
    width: 150%;
    height: 150%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
    left: 40px;
    bottom: 100px; }
  #About #aboutMe {
    display: flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch; }
    #About #aboutMe h1 {
      font-family: "Roboto slab", sans-serif;
      position: relative;
      left: 100px;
      top: 70px;
      margin-bottom: 0px; }
  #About p {
    font-family: "Roboto slab", sans-serif;
    position: relative;
    left: 100px;
    top: 100px;
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-size: 1.5rem;
    width: 80%;
    margin: 0;
    line-height: 35px;
    color: #ebe7e5; }
  #About h1 {
    color: #E5ECED;
    font-size: 2rem; }
  #About .navi {
    margin-top: 20px;
    width: 80%;
    height: 40px;
    border: 1px solid red;
    position: relative;
    left: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: stretch; }
    #About .navi a {
      font-family: "Roboto slab", sans-serif;
      font-size: 1.6rem;
      text-decoration: none;
      color: black;
      border-right: 2px solid black; }
    #About .navi a:nth-child(1) {
      border-left: 2px solid black; }
    #About .navi a:nth-child(n + 2) {
      margin-left: 50px; }

#shuttle {
  height: 60vh;
  width: 60vw;
  background-size: cover;
  position: relative;
  left: 390px;
  top: 70px; }

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  #About p {
    font-family: "Roboto slab", sans-serif;
    position: relative;
    left: 100px;
    top: 100px;
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-size: 12px;
    width: 80%;
    margin: 0;
    line-height: 35px;
    color: #ebe7e5; } }

@font-face {
  font-family: 'blanka';
  src: url(/static/media/blanka-regular-webfont.16e81ce9.ttf) format("truetype"), url(/static/media/blanka-regular-webfont.24d88f87.woff) format("woff"), url(/static/media/blanka-regular-webfont.34eae951.woff2) format("woff2");
  font-weight: normal;
  font-style: normal; }

#Hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%; }

#heroImage {
  width: 500px;
  height: 500px;
  z-index: 999;
  object-fit: contain; }

.heroText {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch; }
  .heroText h1 {
    font-family: 'Blanka';
    color: #E5ECED;
    font-size: 80px;
    font-weight: 700; }
  .heroText p {
    color: #E5ECED;
    font-family: 'Roboto slab', sans-serif;
    font-size: 26px; }
  .heroText a {
    text-decoration: none;
    color: #E5ECED; }
  .heroText a:hover {
    -webkit-filter: invert(100%);
            filter: invert(100%); }

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .heroText h1 {
    font-family: 'Blanka';
    color: #E5ECED;
    font-size: 20px;
    font-weight: 700; }
  .heroText p {
    color: #E5ECED;
    font-family: 'Roboto slab', sans-serif;
    font-size: 12px; } }

/* Landscape */
/* ----------- iPhone 6+, 7+ and 8+ ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */
/* ----------- iPhone X ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*******************************************************/
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

html::-webkit-scrollbar {
  display: none; }

.App {
  height: 500vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y: 40%; }

