@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap");

#About {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  background-color:transparent;

  .image {
    width: 150%;
    height: 150%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
    left: 40px;
    bottom: 100px;
  }

  #aboutMe {
    display: flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;

    h1 {
      font-family: "Roboto slab", sans-serif;
      position: relative;
      left: 100px;
      top: 70px;
      margin-bottom: 0px;
    }
  }

  p {
    font-family: "Roboto slab", sans-serif;
    position: relative;
    left: 100px;
    top: 100px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 1.5rem;
    width: 80%;
    margin: 0;
    line-height: 35px;
    color:  rgb(235, 231, 229);
  }

  h1 {
    color: #E5ECED;
    font-size: 2rem;
  }

  .navi {
    margin-top: 20px;
    width: 80%;
    height: 40px;
    border: 1px solid red;
    position: relative;
    left: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: stretch;

    a {
      font-family: "Roboto slab", sans-serif;
      font-size: 1.6rem;
      text-decoration: none;
      color: black;
      border-right: 2px solid black;
    //   text-align: center;
    }

    a:nth-child(1) {
      border-left: 2px solid black;
    }

    // Apply margin to every anchor in navi, except for the first one.
    // (To preserve
    a:nth-child(n + 2) {
      margin-left: 50px;
    }
  }
}

#shuttle {
  height: 60vh;
  width: 60vw;
  background-size: cover;
  position: relative;
  left: 390px;
  top: 70px;
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    #About {
      p {
        font-family: "Roboto slab", sans-serif;
        position: relative;
        left: 100px;
        top: 100px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 12px;
        width: 80%;
        margin: 0;
        line-height: 35px;
        color:  rgb(235, 231, 229);
      }
    }
}
