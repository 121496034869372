@font-face {
  font-family: "anurati";
  src: url("../../assets/anurati-regular-webfont.woff") format("woff"),
    url("../../assets/anurati-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

#Projects {
  width: 100vw;
  height: 100vh;
  background-color: #243447;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;
  // scroll-snap-align: center;
}

.project {
  color: #E5ECED;
  font-family: "Roboto slab", sans-serif;
  margin: 10px;
  padding: 35px;
  border-width: 15px;
  height: 800px;
  width: 650px;

  h1 {
    font-family: "anurati";
    text-align: center;
    font-size: 26px;
    margin-bottom: 10px;
  }

  .projectImages {
    width: 100%;
    height: 100%;
  }

  p {
    margin-top: 0px;
    font-size: 18px;
  }

  p:nth-child(3) {
    margin-top: 5px;
  }
  
  a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    text-decoration: none;
    color: #E5ECED;
    font-size: 18px;
    position: relative;
    bottom: 30px;

    img {
      margin-right: 10px;
    }
  }

  #github {
    position: relative;
    bottom: 50px;
  }
  #reforge {
    position: relative;
    right: 1px;
  }

}
