@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap');
@font-face {
  font-family: 'blanka';
  src: url('../../assets/blanka_webfont/blanka-regular-webfont.ttf') format('truetype'),
       url('../../assets/blanka_webfont/blanka-regular-webfont.woff') format('woff'),
       url('../../assets//blanka_webfont/blanka-regular-webfont.woff2') format('woff2');
       font-weight: normal;
       font-style: normal;
}

#Hero {
    width: 100%;
    height: 100vh;
    // background-color: #253448;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    // scroll-snap-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
}


#heroImage {
    width: 500px;
    height: 500px;
    z-index: 999;
    object-fit: contain;
}

.heroText {
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
  align-content: stretch;
  
  h1 {
    font-family: 'Blanka';
    color: #E5ECED;
    font-size: 80px;
    font-weight: 700;
  }

  p {
    color:#E5ECED;
    font-family: 'Roboto slab', sans-serif;
    font-size: 26px;
  }

  a {
    text-decoration: none;
    color: #E5ECED;
  }

  a:hover {
    filter: invert(100%);
  }
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    .heroText{
      h1 {
        font-family: 'Blanka';
        color: #E5ECED;
        font-size: 20px;
        font-weight: 700;
      }

      p {
        color:#E5ECED;
        font-family: 'Roboto slab', sans-serif;
        font-size: 12px;
      }
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

  
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {
   
}